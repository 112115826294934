<template>
  <div class="content profile-content">
    <!-- <ProfileNavbar :title="$t('sidebar.settings')"></ProfileNavbar> -->
    <div class="settings-password" v-show="visiblePassword">
      <ProfileNavbar :title="$t('profile.settings.change-password')"></ProfileNavbar>
      <div class="mob-menu-title">
        <button class="mob-menu-back" v-on:click="showScreens(true, false, false)">
          <img src="images/profile/arrow-back.svg">
        </button>
        {{ $t('profile.settings.change-password') }}
      </div>
      <div class="clearfix bg-blue">
        <div class="password-desc">
          <div class="password-desc-title pb-4">
            {{ $t("profile.settings.password-must-contain") }}
          </div>
          <div class="password-desc-item">
            <img height="24" class="pr-4" src="/images/profile/done.svg">
            <div>{{ $t("profile.settings.minimum-symbols") }}</div>
          </div>
          <div class="password-desc-item">
            <img height="24" class="pr-4" src="/images/profile/done.svg">
            <div>{{ $t("profile.settings.number-required") }}</div>
          </div>
          <div class="password-desc-item">
            <img height="24" class="pr-4" src="/images/profile/close_small.svg">
            <div>{{ $t("profile.settings.letter-part") }}</div>
          </div>
          <div class="password-desc-item">
            <img height="24" class="pr-4" src="/images/profile/close_small.svg">
            <div>{{ $t("profile.settings.acceptable-characters") }}</div>
          </div>
        </div>
        <div class="">
          <div class="d-flex settings-wrap">
            <div class="settings-form">
              <h4 class="settings-form-title">{{ $t("profile.settings.form-title") }}</h4>
              <p class="settings-form-subtitle">
                {{ $t("profile.settings.form-subtitle") }}
              </p>
              <div class="form">
                <form-group :label="$t('references.required-field') + $t('profile.settings.old-password-title') " >

                  <el-input
                      type="password" :placeholder="$t('profile.settings.old-password-title')" id="password-old"
                      autocomplete="off" show-password
                      v-model="oldPassword"></el-input>
                </form-group>
                <form @submit.prevent="changePassword">
                  <form-group
                      :class="{'text-danger': $v.newPassword.$invalid && newPassword.length>0}"
                      :field="$v.newPassword"
                      :label="$t('references.required-field') + $t('profile.settings.new-password-title')"
                      name="password">
                    <el-input
                        onkeyup="value=value.replace(/[^\d]/g,'')"
                        type="number"
                        maxlength="4"
                        :class="{'form-control-is-invalid': $v.newPassword.$invalid && newPassword.length>0}"
                        :placeholder="$t('profile.settings.new-password-title')"
                        v-model="newPassword"
                        @change="$v.newPassword.$touch()"
                        show-password>
                    </el-input>
                  </form-group>
                  <form-group
                      :class="{'text-danger': $v.confirmPassword.$invalid && confirmPassword.length>0 }"
                      :field="$v.confirmPassword"
                      :label="$t('references.required-field') + $t('signin-form.password-confirm-label')"
                      name="password">
                    <el-input
                        onkeyup="value=value.replace(/[^\d]/g,'')"
                        type="number"
                        maxlength="4"
                        :class="{'form-control-is-invalid': $v.confirmPassword.$invalid && confirmPassword.length>0 }"
                        :placeholder="$t('signin-form.password-confirm-placeholder')"
                        v-model="confirmPassword"
                        @change="$v.confirmPassword.$touch()"
                        show-password></el-input>
                  </form-group>
                  <div class="submit-btn">
                    <input type="submit" id="submit-btn" class="btn-submit" :value="$t('profile.settings.btn-text')">
                  </div>
                </form>
              </div>

            </div>
            <div class="col-lg-4 col-md-12 settings-recommendation">
              <div class="col-12 px-0">
                <p class="text-primary">{{ $t('signin-form.password-must1') }}</p>
                <p><img src="/images/done.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must2') }}</p>
                <p><img src="/images/done-lamp.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must3') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="settings-mob" v-show="visibleBtns">
      <ProfileNavbar :title="$t('sidebar.settings')"></ProfileNavbar>
      <div class="mob-menu-title">
        <!-- <router-link tag="a" to="/profile"  class="mob-menu-back">
            <img src="images/profile/arrow-back.svg">
        </router-link> -->
        {{ $t('sidebar.settings') }}
      </div>
      <button class="settings-btn mt-4" v-on:click="showScreens(false, true, false)">
        <img src="/images/profile/change_password_icon.svg">
        {{ $t("profile.settings.change-password") }}
      </button>
      <button class="settings-btn" v-on:click="showScreens(false, false, true)">
        <img src="/images/profile/language_icon.svg">
        {{ $t("profile.settings.change-language") }}
      </button>
      <button @click="logoutMethod()" class="settings-btn settings-btn--blue">
        <img src="/images/profile/exit.svg">
        {{ $t("profile.settings.sign-out") }}
      </button>
    </div>
    <div class="settings-language" v-show="visibleLanguage">
      <ProfileNavbar :title="$t('profile.settings.change-language')"></ProfileNavbar>
      <div class="mob-menu-title">
        <button class="mob-menu-back" v-on:click="showScreens(true, false, false)">
          <img src="images/profile/arrow-back.svg">
        </button>
        {{ $t('profile.settings.change-language') }}
      </div>
      <div class="language-title pt-4 pb-5">
        {{ $t("profile.settings.select-language") }}
      </div>
      <div class="language-select">
        <div class="time-check">
          <label class="time-check-label" for="lang-kz">
            <input @click="switchLocale('kz')" class="time-check-input" type="radio" name="select-lang" id="lang-kz"
                   value="1">
            <div class="time-check-block">
              <span class="time-checkmark"></span>
              <span class="time-check-label-title">{{ $t("profile.settings.kz") }}</span>
            </div>
          </label>
        </div>
        <div @click="switchLocale('ru')" class="time-check">
          <label class="time-check-label" for="lang-ru">
            <input class="time-check-input" type="radio" name="select-lang" id="lang-ru" value="2">
            <div class="time-check-block">
              <span class="time-checkmark"></span>
              <span class="time-check-label-title">{{ $t("profile.settings.ru") }}</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import {mapActions} from "vuex";
import {minLength, sameAs} from "vuelidate/lib/validators";

export default {
  name: 'Settings',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      visibleBtns: false,
      visiblePassword: true,
      visibleLanguage: false,
      iinLoading: false,
      loading: false,
      newPassword: '',
      oldPassword: '',
      confirmPassword: '',
      apiRoot: window.API_ROOT,

    }
  },
  validations: {
    newPassword: {
      required: false,
      minLength: minLength(4),
      valid: function (value) {
        if(value.length>=4){
          return /\d{3}[1-9]/.test(value)
        }
        else{return false}
      }
    },
    confirmPassword: {
      required: false,
      sameAsnewPassword: sameAs('newPassword'),
      minLength: minLength(4),
      valid: function (value)
      {
        if(value.length>=4){
          return /\d{3}[1-9]/.test(value)
        }
        else {return false}}
    },
  },
  mounted() {
    if (this.$i18n.locale == 'ru') {
      document.getElementById('lang-ru').checked = true
    } else {
      document.getElementById('lang-kz').checked = true
    }
    if ($('body').width() <= 992) {
      this.visiblePassword = false;
      this.visibleLanguage = false;
      this.visibleBtns = true;
    } else {
      this.visiblePassword = true;
      this.visibleLanguage = false;
      this.visibleBtns = false;
    }
    if ($('body').width() <= 992 && this.visiblePassword === true) {
      this.visibleBtns = false;
      this.visibleLanguage = false;
    }

  },
  methods: {

    logoutMethod() {
      this.logout();
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      sessionStorage.setItem('avatar-url', '')
      window.location = '/';
    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
    },
    showScreens(visibleBtns, visiblePassword, visibleLanguage) {
      this.visibleBtns = visibleBtns;
      this.visiblePassword = visiblePassword;
      this.visibleLanguage = visibleLanguage;
    },
    changePassword() {
      this.loading = true;

      if (this.oldPassword==''||this.newPassword !== this.confirmPassword || this.$v.confirmPassword.$invalid || this.$v.newPassword.$invalid ) {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.password-error'),
          type: 'error'
        });

        return
      }

      this.$http.post(window.API_ROOT + '/api/password/update', {
        password: this.newPassword,
        old_password:this.oldPassword,
        password_confirmation:this.confirmPassword
      })
          .then((res) => {
            Vue.toastr({
                  message: this.$t('profile.settings.request-accept') + '!',
                  description: this.$t('profile.settings.password-success'),
                  type: 'success',
                },
                this.$router.go(),
            );

            this.loading = false;
          }).catch((e) => {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.password-error'),
          type: 'error'
        });
        this.loading = true;

      });
    },
    ...mapActions({
      'logout': 'logout'
    }),
  },
}


</script>
<style lang="postcss" scoped>
/deep/ .form-control-is-invalid .el-input__inner {
  border: 1px solid #dc3545 !important;
  color: #dc3545 !important;
}
/deep/ .form-control-is-invalid .el-input__inner:hover {
  border-color: #dc3545 !important;
}
.form-group {
  display: flex;
  flex-direction: column;
}
.settings-recommendation {
  margin-left: 1rem;
}


.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-submit {
  margin-top: 20px;
  background: #04C2F4;
  border-radius: 5px;
  padding: 12px 36px;
  font-size: 15px;
  color: #ffffff;
  border: none;
}

.pwd-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */


  /* Текст */

  color: #303030;
}

.settings-form-title {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #303030;
}

.settings-form-subtitle {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A9A9A9;
}

.mob-menu-back {
  padding-right: 20px;
  position: inherit;
  padding-top: 0;
}

@media (max-width: 991px) {
  .settings-wrap {
    flex-direction: column
  }

  .settings-recommendation {
    margin-left: 0;
    display: none !important;
  }
}

</style>